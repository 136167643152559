<template>
  <div id="app">
    <router-view v-if="appName" />
  </div>
</template>

<script>
function addCssByLink(url) {
  var doc = document;
  var link = doc.createElement("link");
  link.setAttribute("rel", "icon");
  link.setAttribute("type", "image/png");
  link.setAttribute("href", url);
  link.setAttribute("size", "32x32");

  var heads = doc.getElementsByTagName("head");
  if (heads.length) heads[0].appendChild(link);
  else doc.documentElement.appendChild(link);
}

export default {
  data() {
    return {};
  },
  mounted() {},
  computed: {
    logo() {
      return this.$store.state.logo;
    },
    appName() {
      return this.$store.state.appName;
    },
  },
};
</script>
<style>
@import "./assets/images/hb/index.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app,
.page,
.content {
  height: 100vh;
  /*background: url(./assets/images/hb/ios.png) no-repeat;*/
  background-size: cover;
  /* background: #03041c; */
}
#app {
  background-image: linear-gradient(#03041c, #03041cdb);
}
input {
  background: #fff;
  outline: none;
  border: none;
  font-size: 14px;
}
</style>
