import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import {typeFn as type} from '../utils'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect:'/auth'
  // },
  {
    path: '/auth',
    name: 'auth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/auth.vue')
  },
  {
    path: '/download',
    name: 'download',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/download.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
  const {path} = to;
  if(type=='pc'){
      if((path=='/' || path=='/auth')){
        next('/download')
        return 
      }
  }else{
    const authCode = store.state.authCode;
    if(!authCode && path!='/auth'){
      next('/auth?t='+new Date().getTime())
      return
    }
    if(path == '/auth' && new Date().getTime() !=to.query.t){
      next('/auth?t='+new Date().getTime())
      return
    }

  }




    

 
  
  next()
  }
)

export default router
