const typeFn = ((req) => {
    let u =  navigator.userAgent;
    let ua = u;
    if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      if (/Safari/.test(ua)) {
        return "ios_true";
      } else {
        return "ios_false";
      }
    } else if (u.indexOf("Android") > -1) {
      return "android";
    } else {
      return "pc";
    }
  })()

export  {
    typeFn
}