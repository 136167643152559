import Vue from "vue";
import Vuex from "vuex";
// import config from '../../public/config'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authCode: null,
    appName: '',
    logo: "logo.png",
  },
  mutations: {
    setAuthCode(state, payload) {
      state.authCode = payload.data;
    },
  },
  actions: {},
  modules: {},
});
